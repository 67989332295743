@import "~bootstrap/scss/bootstrap";

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }
}

footer {
  padding: 1rem 0.5rem;
}
